.main-title{
        display: flex;
        justify-content: center;
        font-size: 24px;
}

.ui.message.box-transparent{
    color:black;
    background: transparent;
}

.positive-text {
    color:#4DAF50 !important;
}

.positive-background{
    background: #4DAF50 !important;
    color:white !important;
}

.negative-text{
    color:#9f3a38 !important;
}

.negative-background{
    background: #b91c1c !important;
    color:white !important;
}