.mt-1,
.my-1 {
    margin-top: 5px !important;
}

.mt-2,
.my-2 {
    margin-top: 10px !important;
}

.colorWhite {
    color: #ffffff !important;
}
.colorBlack {
    color: #000000 !important;
}

.color-theme-gray .footer a.btn-link-default {    
    color: #767676 !important;
}

.bottom-15 {
    bottom: -15px !important;
}

.btn-menu {
    font-size: 16px;
    text-decoration: none;
}

.dic-search-result-count {
	color: #e87347;
}

.text-highlight-orangered {
	color: orangered;
}
.text-highlight-blue {
    color: #3490dc;
}

.btn-border-grey {
    border: solid 1px #a9a6a6 !important;
}
.btn-group-label-color {
    background-color: #ffffff !important;
    border: solid 1px #0088cc !important;
}
.btn-group-icon-color {
    color: #0088cc;
}

.btn-border-radius-none {
    display: block;
    width: 100%;
    background: transparent none !important;
    color: rgba(0, 0, 0, 0.82) !important;
    font-weight: 500;
    text-transform: none;
    text-shadow: none !important;
    cursor: pointer;
    min-height: 1em;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em .78571429em;
    border: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
    border-radius: 0 !important;
}
.btn-border-radius-none:hover {
    background: #e0e1e2!important;
}

.main-notice-bgcolor  {
    color: #ffffff !important;
	background-color: #687378 !important;
}

.main-notice-bgcolor-event  {
    color: #ffffff !important;
	background-color: #f36b4f !important;
}

.label-result-box {
	color: rgba(0, 0, 0, 0.82) !important;
	background-color: rgba(232, 232, 232, 0) !important;
	border-right: 1px solid !important;
	border-radius: 0px !important;
    padding: .1rem 0rem .1rem .5rem !important;
    margin-top: .4rem;
}

.line-height-20 {
    line-height: 2rem;
}

.m-0 {
    margin: 0 !important
}

.posi-relative {
    position: relative !important;
}

.min-height-30px {
    min-height: 30px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.text-underline {
    text-decoration: underline !important;
}

.text-highlight {
    color: #f37603!important;
    font-weight: 500!important;
}

.dp-flex-wrap {
	display: flex !important;
	flex-flow: wrap;
}

.search-result-title-bg {
    font-weight: 600;
    color: #000000;
    background-color: #9d97b3!important;
}

.label-radius-7 {
	border-radius: .7rem !important;
}

.text-link {
    color: #1f4bcc!important;
    font-weight: 500!important;
}

.custom-modal {
    all: initial;    
}
.custom-header{
    all: initial;
    position: initial !important;
}

.mt-7,
.my-7 {
    margin-top: 35px !important;
}

.mt-8,
.my-8 {
    margin-top: 40px !important;
}

.mt-9,
.my-9 {
    margin-top: 45px !important;
}

.mt-10,
.my-10 {
    margin-top: 50px !important;
}

.page-footer {
    background: #37474F;    
    padding-top: 20px;
    color: #CFD8DC;
}
.footer-content {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: calc(env(safe-area-inset-left) + 20px);
    padding-right: calc(env(safe-area-inset-right) + 20px);
}
.page-footer .logo{
    font-weight: bold;
    font-size: 20px;
    color: #CFD8DC;
}
.page-footer .logo:after{
    display: block;
    position: relative;
    content: " ";
    height: 2px;
    width: 30px;
    margin: 15px 0 0px 0;
    background: #B0BEC5;
}
.page-footer .text{
    color: #CFD8DC;
}
.page-footer li{
    margin: 0 0 10px 0;
}
.page-footer li a{
    color: #CFD8DC;
}
.page-footer li a i{
    margin-right: 10px;
}
.page-footer .footer-copyright{
    background: rgba(50,50,50,0.3);
}
.page-footer .footer-copyright .container, .page-footer .footer-copyright .container a{
    color: #78909C;
    font-size: 13px;
}

.page-footer.light-bg{
    background-color: #ffffff;
}
.page-footer.light-bg .footer-copyright {
    background: rgba(150,150,150,0.1);
}

.page-footer.white-text *, .page-footer.white-text a, .page-footer.white-text .social a i{
    color: #ffffff;
}
.page-footer.white-text .logo:after{
    background: #f5f5f5;
}
.page-footer.white-text .footer-copyright .container, .page-footer.white-text .footer-copyright .container a{
    color: #eeeeee;
}

.page-footer.dark-text *, .page-footer.dark-text a, .page-footer.dark-text .social a i{
    color: #455A64;
}
.page-footer.dark-text .logo:after{
    background: #607D8B;
}
.page-footer.dark-text .footer-copyright .container, .page-footer.dark-text .footer-copyright .container a{
    color: #607D8B;
}

.max-width-300 {
    max-width: 300px !important;
}
/* 
=========================================================
table  
=========================================================
*/
.table-header {
    background: #f9fafb;
}
.table-tr-light {
    background-color: rgba(0,0,50,.02);
}
/* 
=========================================================
Login, Join, CheckEmail  
=========================================================
*/
.valid-feedback {
    display: block;
    border-radius: 15px;
    padding: .5rem 1rem;
    font-size: .76rem;
    color: #fff;
    background: #c43d4b;
    text-align: center;
    
    position: absolute;
    z-index: 1175;
    margin-top: -1.3rem;
    left: 50%;
    transform: translateX(-50%);
}
.valid-feedback::after {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: 5px solid #c43d4b;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.preview-content-wrapper .qr-code-wrapper {
    position: relative;
    z-index: 1;
    background-color: cadetblue;
    text-align: center;
    max-width: 18rem;
    padding: 1rem;
    border-radius: .5rem; }
.preview-content-wrapper .qr-code-wrapper::after {
    position: absolute;
    width: 200px;
    height: 200px;
    background-repeat: repeat;
    bottom: -70px;
    left: -70px;
    z-index: -10;
    content: "";
    border-radius: 50%;
    opacity: 0.15; }
.preview-content-wrapper .qr-code-wrapper h6 {
    font-size: 14px; }
/* 
=========================================================
Font  
=========================================================
*/
.text-07 {
    font-size: .7rem !important;
}
.text-08 {
    font-size: .8rem !important;
}
.text-09 {
    font-size: .9rem !important;
}
.text-11 {
    font-size: 1.1rem !important;
}
.text-13 {
    font-size: 1.3rem !important;
}
.text-15 {
    font-size: 1.5rem !important;
}
.text-17 {
    font-size: 1.7rem !important;
}
.text-20 {
    font-size: 2.0rem !important;
}
.text-23 {
    font-size: 2.3rem !important;
}
.text-25 {
    font-size: 2.5rem !important;
}
.text-27 {
    font-size: 2.7rem !important;
}
.text-30 {
    font-size: 3.0rem !important;
}
.text-32 {
    font-size: 3.2rem !important;
}
.text-35 {
    font-size: 3.5rem !important;
}

.font-100 {
    font-weight: 100;
}

.font-200 {
    font-weight: 200;
}

.font-300 {
    font-weight: 300;
}

.font-400 {
    font-weight: 400;
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-700 {
    font-weight: 700;
}

.font-800 {
    font-weight: 800;
}

.font-900 {
    font-weight: 900;
}