$bg-color: #617e81;
$foreground-color: #fff;
$separator-color: #d7d7d7;
$primary-color:#145388;

$breakpoints: (
  xxs: 420px,
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px
);

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

.page-item {
    padding: 10px;
    /*
    @include respond-below(sm) {
      padding: 3px;
    }
    */
    .page-link {
      outline: initial !important;
      box-shadow: initial !important;
      line-height: 1.7;
      border-radius: 40px !important;
      min-width: 38px;
      text-align: center;
      height: 38px;
      padding: 0.55rem 0;
      /*
      @include respond-below(xs) {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem;
      }
      */
      &.next {
        background: $bg-color;
        color: $foreground-color;
        border: 1px solid $bg-color;
      }
      &.prev,
      &.prev {
        background: $bg-color;
        border: 1px solid $bg-color;
        color: $foreground-color;
      }
      &.first,
      &.last {
        background: transparent;
        color: $bg-color;
        border: 1px solid $bg-color;
        border-radius: 30px;
        &:hover {
          background: $bg-color;
          color: $foreground-color;
          border: 1px solid $bg-color;
        }
      }
      &:hover {
        background-color: transparent;
        border-color: lighten($bg-color, 10%);
        color: $bg-color;
      }
    }
    &.active .page-link {
      background: transparent;
      border: 1px solid $bg-color;
      color: $bg-color;
    }
    &.disabled .page-link {
      border-color: $separator-color;
      color: $separator-color;
      background: transparent;
    }
  }
  .page-link {
    background-color: transparent;
    border-color: transparent;
    color: $primary-color;
  }
  .btn-sm.page-link {
    padding: 0.5rem 0.5rem;
  }
  .pagination-lg {
    .page-item {
      padding: 15px;
      /*
      @include respond-below(sm) {
        padding: 3px;
      }*/
      .page-link {
        min-width: 50px;
        height: 50px;
        /*
        @include respond-below(sm) {
          min-width: 38px;
          height: 38px;
          padding: 0.55rem 0;
          line-height: 1.5;
          font-size: 0.8rem;
        }
        @include respond-below(xs) {
          min-width: 30px;
          height: 30px;
          line-height: 0.9;
          font-size: 0.76rem;
        }
        */
      }
    }
  }
  .pagination-sm {
    .page-item {
      padding: 6px;
      /*
      @include respond-below(sm) {
        padding: 3px;
      }*/
      .page-link {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem;
      }
    }
  }